import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { usePrevious } from '@react-hooks-library/core';

import { showNotification, showPopupMenu } from "actions/App";
import {
  createCustomRecipe,
  fetchCustomRecipe,
  getTotalTimeSeconds,
  updateCustomRecipe,
  saveImage,
} from 'actions/CustomRecipes';
import { errorModalProps } from 'actions/helpers';
import { closeModal, openModal } from 'actions/Modal';
import { nlpProcessorForOneLine, nlpProcessorForMultipleLines } from 'actions/NLP';
import { showPaywallModal } from 'actions/Paywall';
import { loadRecipe } from 'actions/Recipe';
import { urlTester } from 'actions/Recipes';
import { yumRecipe } from 'actions/Yums';
import DropdownMenu from 'components/DropdownMenu';
import GenericNotFound from 'components/GenericNotFound';
import ImageUpload from 'components/ImageUpload';
import ActionBar from 'components/Pasta/ActionBar';
import Button from 'components/Pasta/Button';
import * as types from 'constants/ActionTypes';
import { pstIcons } from 'constants/FontFaceCharacters';
import { ALL_PERSONAL_RECIPES } from 'constants/index';
import { CHECKOUT_SOURCES } from 'constants/Pro';

import { CUSTOM_RECIPE_ADD_ROUTE, CUSTOM_RECIPE_EDIT_ROUTE, HOME_ROUTE } from 'constants/routes';
import { authUsernameSelector, isLoggedInSelector } from 'selectors/auth';
import { isProUser } from 'selectors/pro';
import { getStringFunction } from 'util/localStrings';
import { secondsToTime } from 'util/number';
import { heightPx, isValidHttpUrl, setProtocolToLowerCase } from 'util/string';
import focusInput from 'util/focusInput';


const IMAGE_URL = "imageURL";
const INGREDIENT_LINES = "ingredientLines";
const INGREDIENTS = "ingredients";
const PREPARATION = "preparation";
const RAW_LINE = "rawLine";
const TITLE = "title";
const TOTAL_TIME_SECONDS = "totalTimeSeconds";
const URL = "url";

const str = getStringFunction(
  [
    {
      id: 'personalRecipe.addCustomRecipe',
      defaultMessage: "Create personal recipe",
    },
    {
      id: 'personalRecipe.collectionUpdated',
      defaultMessage: "Collection Updated",
    },
    {
      id: 'personalRecipe.editCustomRecipe',
      defaultMessage: "Edit personal recipe",
    },
    {
      id: 'personalRecipe.directions',
      defaultMessage: "Directions",
    },
    {
      id: 'personalRecipe.directionsInstructions',
      defaultMessage: "Jot down ideas, instructions, or any other notes for this recipe",
    },
    {
      id: 'personalRecipe.ingredients',
      defaultMessage: "Ingredients",
    },
    {
      id: 'personalRecipe.ingredientsInstructions',
      defaultMessage: "Add ingredient",
    },
    {
      id: 'personalRecipe.multipleIngredientsDetected',
      defaultMessage: "Multiple ingredients detected. Enter one ingredient at a time for best results.",
    },
    {
      id: 'personalRecipe.link',
      defaultMessage: "Link",
    },
    {
      id: 'personalRecipe.linkInstructions',
      defaultMessage: "Add link URL",
    },
    {
      id: 'personalRecipe.noProtocol',
      defaultMessage: "Please add http:// or https:// to the beginning of the link.",
    },
    {
      id: 'personalRecipe.invalidUrl',
      defaultMessage: "The link you entered is invalid. Please check your link and try again.",
    },
    {
      id: 'personalRecipe.recipeSaved',
      defaultMessage: "Recipe saved to your Collections",
    },
    {
      id: 'personalRecipe.title',
      defaultMessage: "Title",
    },
    {
      id: 'personalRecipe.titleInstructions',
      defaultMessage: "Add title",
    },
    {
      id: 'personalRecipe.totalTime',
      defaultMessage: "Total Time:",
    },
    {
      id: 'personalRecipe.titleRequired',
      defaultMessage: "Title required. Please add one to save this recipe.",
    },
  ],
  'personalRecipe',
);



export function CustomRecipeComposer(props) {
  const dispatch = useDispatch();
  const { image, isLoggedIn, match = {} } = props;
  const { id } = match.params || {};
  const [finalIngredientsPayload, setFinalIngredientsPayload] = useState({});
  const [hasError, setHasError] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [recipeDirections, setRecipeDirections] = useState({
    value: "",
    error: "",
  });
  const [recipeHeader, setRecipeHeader] = useState(str("addCustomRecipe"));
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [recipeLink, setRecipeLink] = useState({
    value: "",
    error: "",
  });
  const [recipeTitle, setRecipeTitle] = useState({
    value: "",
    error: "",
  });
  const [recipeTotalTime, setRecipeTotalTime] = useState({
    hourValue: 0,
    minuteValue: 0,
  });
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showHoursDropdownMenu, setShowHoursDropdownMenu] = useState(false);
  const [showMinutesDropdownMenu, setShowMinutesDropdownMenu] = useState(false);
  const recipeIngredientsPreviousLength = usePrevious(recipeIngredients.length);
  const isAddMode = __CLIENT__ && props.location.pathname.includes('/add');
  const isEditMode = __CLIENT__ && props.location.pathname.includes('/edit') && id;

  const setIngredient = (ingredient, quantity = "", unit = "") => {
    const ingredientLine = quantity + unit + ingredient;

    const parsedIngredient = <div key={ingredientLine}>
      {!isEmpty(quantity) && <span>{quantity}</span>}
      {!isEmpty(unit) && <span>{unit}</span>}
      <span className="p3-text font-bold">{ingredient}</span>
    </div>;

    setRecipeIngredients((prevState) => ([
      ...prevState,
      {
        value: ingredientLine,
        editable: false,
        html: parsedIngredient,
      },
    ]));
  };

  const parseIngredientLine = (data) => {
    let ingredient = data[INGREDIENT_LINES][0][RAW_LINE];
    let quantity = "";
    let unit = "";

    data[INGREDIENT_LINES].forEach((i) => {
      ingredient = i.rawLine;

      if (i.hasOwnProperty(INGREDIENTS) && !isEmpty(i.ingredients)) {
        i.ingredients.forEach((j, index) => {
          ingredient = j.ingredientLabel;

          // Display Toast only once per entry
          if (index === 1) {
            props.showNotification({
              title: str('multipleIngredientsDetected'),
            });
          }

          if (!isEmpty(j.quantity)) {
            quantity = j.quantity.label + " ";
          }

          if (!isEmpty(j.unit)) {
            unit = j.unit.label + " ";
          }

          setIngredient(ingredient, quantity, unit);
          quantity = "";
          unit = "";
        });
      } else {
        setIngredient(ingredient);
      }
    });
  };

  // TODO post-MVP: refactor to useRef instead of DOM
  const calculateIngredientsHeight = () => {
    if (__CLIENT__) {
      const ingredientsList = document.querySelector('.composer-ingredients-list');

      if (ingredientsList !== null) {
        const heightPerIngredient = 50; // 50px
        ingredientsList.style.maxHeight = heightPx(ingredientsList.length * heightPerIngredient);

        // scroll to the bottom only when new ingredient(s) is/are added
        if (recipeIngredientsPreviousLength < recipeIngredients.length) {
          document.querySelector(".composer-ingredients-list .composer-ingredient-line:last-child").scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }
    }
  };

  const saveRecipeFound = (recipeId) => {
    return {
      modalType: 'custom-recipe-found',
      title: 'Recipe found',
      subheading: 'Yummly has this recipe. Would you like to add it to your Saved Recipes?',
      includeCloseButton: true,
      buttons: [
        {
          text: 'Add to Saved Recipes',
          clickHandler: () => {
            // yumRecipe() requires a specific recipe object, hence getting it from loadRecipe()
            setHasError(false);
            setSaveButtonClicked(false);
            props.loadRecipe(recipeId).then((result) => {
              props.yumRecipe(result.recipe);
            });

            props.showNotification({
              title: str('collectionUpdated'),
            });
          },
        },
        {
          text: 'No, Thanks',
          type: 'secondary',
          clickHandler: () => {
            props.closeModal();
            setHasError(false);
          },
        },
      ],
      // TODO pass through a "children" prop with <Image src={CR's image url} />
      // (modal does not currently support children prop)
    };
  };

  const onKeyDownEditIngredientInput = useCallback((oldValue, e) => {
    const keyListenerList = ['Enter', 'Tab'];
    const newValue = e?.target?.value;

    if (keyListenerList.includes(e.key)) {
      e.preventDefault();
      const updated = recipeIngredients.map(obj => {
        if (obj.value === oldValue) {
          // can't call setIngredient() here because it will just add on vs a replacement
          return {
            value: newValue,
            editable: false,
            html: <div className="p3-text font-bold">{newValue}</div>,
          };
        }
        return obj;
      });

      setRecipeIngredients(
        updated
      );
    }
  });

  const onKeyUpIngredientInput = (e) => {
    const value = e?.target?.value?.trim();
    if (value && e.key === 'Enter') {
      props.nlpProcessorForOneLine(value).then((data) => {
        if (data) {
          parseIngredientLine(data);
        } else { // nlpProcessor returned an error so just leave value as-is.
          setIngredient(value);
        }
      });
      e.target.value = "";
    }
  };

  const onChangeDirectionsInput = (e) => {
    setRecipeDirections({
      value: e?.target?.value, // don't trim whitespace
      error: "",
    });
  };

  const onBlurLinkInput = async (e) => {
    let value = e?.target?.value?.trim();
    const error = {};
    value = setProtocolToLowerCase(value);

    if (!isEmpty(value)) {
      if (isValidHttpUrl(value)) {
        // Call API only if url changes
        if (value !== recipeLink.value) {
          const result = await props.urlTester(value);
          if (result) {
            props.openModal(saveRecipeFound(result["recipe-id"]));
            setRecipeLink({
              value,
              error: "",
            });
            setHasError(true);
            error.reason = "Recipe found";
          } else {
            setRecipeLink({
              value,
              error: "",
            });
            setHasError(false);
          }
        }
      } else {
        setRecipeLink({
          value,
          error: str("invalidUrl"),
        });
        setHasError(true);
        error.reason = str("invalidUrl");
      }
    } else {
      setRecipeLink({
        value: "",
        error: "",
      });
      setHasError(false);
    }
    setHasError(error?.reason?.length);
    return error;
  };

  const onKeyUpTitleInput = (e) => {
    setRecipeTitle({
      value: e?.target?.value?.trim(),
      error: "",
    });
    setHasError(false);
  };

  // This method toggle hours & (if needed) minutes drop down menu
  const toggleHoursMenu = ({ showMenu }) => {
    setShowHoursDropdownMenu(showMenu);

    // do not show Minutes' menu when Hours' menu is open
    if (showMenu) {
      setShowMinutesDropdownMenu(false);
    }
  };

  // This method toggle minutes & (if needed) hours drop down menu
  const toggleMinutesMenu = ({ showMenu }) => {
    setShowMinutesDropdownMenu(showMenu);

    // do not show Hours' menu when Minutes' menu is open
    if (showMenu) {
      setShowHoursDropdownMenu(false);
    }
  };

  // This method gets the hours' newly selected value in dropdown menu
  const handleHours = (e) => {
    const value = e.currentTarget.getAttribute("data-value");

    setRecipeTotalTime((prevState) => ({
      ...prevState,
      hourValue: value,
    }));
  };

  // This method gets the minutes' newly selected value in dropdown menu
  const handleMinutes = (e) => {
    const value = e.currentTarget.getAttribute("data-value");

    setRecipeTotalTime((prevState) => ({
      ...prevState,
      minuteValue: value,
    }));
  };

  const onClickEditIngredient = useCallback((ingredientObj) => () => {
    const updated = recipeIngredients.map(obj => {

      if (obj.value === ingredientObj.value) {
        return {
          value: obj.value,
          editable: true,
        };
      }
      return obj;
    });

    setRecipeIngredients(
      updated,
    );
  });

  const onClickDeleteIngredient = useCallback((value) => () => {
    setRecipeIngredients(recipeIngredients.filter(_value => _value !== value));
  });

  const onClickIngredient = useCallback((ingredient, buttonId) => {
    props.showPopupMenu({
      options: [
        {
          text: 'Edit',
          iconCode: pstIcons.F0003_00B__Icon_edit,
          onClick: onClickEditIngredient(ingredient),
        },
        {
          text: 'Delete',
          iconCode: pstIcons.F0003_025__Icon_bin,
          onClick: onClickDeleteIngredient(ingredient),
        },
      ],
      position: {
        id: buttonId,
      },
    });
  });

  useEffect(() => {
    if (isEditMode) {
      setRecipeHeader(str("editCustomRecipe"));
      props.fetchCustomRecipe(id).then((data) => {
        if (data) {

          if (data.hasOwnProperty(IMAGE_URL)) {
            setImageURL(data.imageURL);
          }

          if (data.hasOwnProperty(INGREDIENT_LINES)) {
            parseIngredientLine(data);
          }

          if (data.hasOwnProperty(PREPARATION)) {
            setRecipeDirections({
              value: data.preparation,
            });
          }

          if (data.hasOwnProperty(TITLE)) {
            setRecipeTitle({
              value: data.title,
            });
          }

          if (data.hasOwnProperty(TOTAL_TIME_SECONDS)) {
            const totalTime = secondsToTime(data.totalTimeSeconds);
            setRecipeTotalTime({
              hourValue: totalTime.hours,
              minuteValue: totalTime.minutes,
            });
          }

          if (data.hasOwnProperty(URL)) {
            setRecipeLink({
              value: data.url,
            });
          }
        }
      });
    }
    if (isAddMode) {
      dispatch({
        type: types.CUSTOM_RECIPES_CLEAR_RECIPE_ITEM,
      });
    }
  }, []);

  useEffect(() => {
    // calculate only when the length changes
    if (recipeIngredientsPreviousLength !== recipeIngredients.length) {
      calculateIngredientsHeight();
    }
  }, [recipeIngredients]);

  useEffect(() => {
    setImageURL(image);
  }, [image]);

  useEffect(() => {
    if (saveButtonClicked && !hasError) {
      const payload = {
        preparation: recipeDirections.value,
        imageURL,
        ingredientLines: finalIngredientsPayload.ingredientLines,
        title: recipeTitle.value,
        totalTimeSeconds: getTotalTimeSeconds(recipeTotalTime.hourValue + ":" + recipeTotalTime.minuteValue),
        url: recipeLink.value,
      };

      if (isAddMode) {
        props.createCustomRecipe(payload).then((id) => {
          setSaveButtonClicked(false);
          if (id) {
            props.history.push(`/custom-recipe/${id}`);
            props.showNotification({
              title: str('recipeSaved'),
            });
          } else {
            if (props.error) {
              props.openModal(errorModalProps());
            }
          }
        });
      } else if (isEditMode) {
        props.updateCustomRecipe(id, payload).then((result) => {
          setSaveButtonClicked(false);
          if (result) {
            props.history.push(`/custom-recipe/${id}`);
            props.showNotification({
              title: str('recipeSaved'),
            });
          } else {
            if (props.error) {
              props.openModal(errorModalProps());
            }
          }
        });
      }
    }
  }, [hasError, saveButtonClicked]);

  if (props.error) {
    return <GenericNotFound />;
  }

  const formDirections = () => {
    return (
      <>
        <label className="composer-directions-text pst-h5">{str('directions')}</label>
        <textarea
          onChange={onChangeDirectionsInput}
          className="composer-directions-field"
          type="text"
          value={recipeDirections.value}
          placeholder={str('directionsInstructions')}
        />
      </>
    );
  };

  const handleImageError = e => {
    // TODO: openModal(...)
    console.error(e); // eslint-disable-line
  };

  const formImage = () => {
    return (
      <ImageUpload
        action={props.saveImage}
        className="composer-image"
        errorFunction={handleImageError}
        image={image}
        pstIcon="F0003_42A__Icon_image_stack"
      />
    );
  };

  const ingredientsList = () => {
    return recipeIngredients.map((ingredient, index) => {
      const buttonId = `ingredient-index-${index}`;

      function onClickTemp() {
        onClickIngredient(ingredient, buttonId);
      }

      function onKeyDownEditTemp(e) {
        onKeyDownEditIngredientInput(ingredient.value, e);
      }
      const line = <div className="composer-ingredient-line" key={ index }>
        {
          ingredient.editable ? (
            <input
              onKeyDown={onKeyDownEditTemp} // onKeyDown recognizes 'Tab' key.
              className="composer-edit-ingredient-field"
              type="text"
              defaultValue={ingredient.value}
              enterkeyhint="enter"
            />
          ) : ingredient.html
        }

        { !ingredient.editable && <div className="ingredient-line-flex-expander" /> }
        { !ingredient.editable && <Button onClick={onClickTemp} iconCode={pstIcons.F0003_013__Icon_overflow} contained={true} small id={buttonId}/>}

      </div>;
      return line;
    });
  };

  const formIngredients = () => {
    return (
      <>
        <label className="composer-ingredients-text pst-h5">{str("ingredients")}</label>
          {recipeIngredients.length > 0 && <ul className="composer-ingredients-list">{ingredientsList()}</ul>}
          <input
            onKeyUp={onKeyUpIngredientInput} // onKeyUp recognizes 'Enter', whereas onChange doesn't.
            className="composer-ingredient-field"
            type="text"
            placeholder={str("ingredientsInstructions")}
            aria-label={str("ingredientsInstructions")}
            enterkeyhint="enter"
          />
      </>
    );
  };

  const formLink = () => {
    return (
      <>
        <label className="composer-link-text pst-h8">{str("link")}
          {!isEmpty(recipeLink.error) && <div className="composer-link-error text-input-error error-message micro-caps font-bold">{recipeLink.error}</div>}
          <input
            onBlur={onBlurLinkInput}
            className="composer-link-field"
            type="text"
            defaultValue={recipeLink.value}
            placeholder={str("linkInstructions")}
          />
        </label>
      </>
    );
  };

  const formTitle = () => {
    return (
      <>
        <label className="composer-title-text pst-h8">{str("title")}<span className="required-field"/>
          {!isEmpty(recipeTitle.error) && <div className="composer-title-error text-input-error error-message micro-caps font-bold">{recipeTitle.error}</div>}
          <input
            onKeyUp={onKeyUpTitleInput}
            className="composer-title-field"
            type="text"
            placeholder={str("titleInstructions")}
            defaultValue={recipeTitle.value}
          />
        </label>
      </>
    );
  };

  const FormTotalTime = () => {
    return (
      <>
        <div className="composer-total-time">
          <label className="composer-total-time-text pst-h8">{str("totalTime")}</label>
          <div className="hours">
            <DropdownMenu
              action={handleHours}
              dropDownLabel={"Hours"}
              handleShowMenu={toggleHoursMenu}
              optionsList={range(0, 24)}
              selectedOption={recipeTotalTime.hourValue}
              _showMenu={showHoursDropdownMenu}/>
          </div>

          <div className="minutes">
            <DropdownMenu
              action={handleMinutes}
              dropDownLabel={"Minutes"}
              handleShowMenu={toggleMinutesMenu}
              optionsList={range(0, 60)}
              selectedOption={recipeTotalTime.minuteValue}
              _showMenu={showMinutesDropdownMenu}/>
          </div>
        </div>
      </>
    );
  };

  const handleTitle = () => {
    if (isEmpty(recipeTitle.value)) {
      setRecipeTitle({
        error: str("titleRequired"),
      });
      setHasError(true);
    }
  };

  const handleSaveRecipeButton = async () => {
    // e.preventDefault();

    handleTitle();

    const titleField = document.querySelector(".composer-title-field");
    if (isEmpty(titleField.value)) {
      focusInput(titleField);
    } else {
      const linkField = document.querySelector(".composer-link-field");
      const linkError = await onBlurLinkInput({
        target: {
          value: linkField.value,
        },
      });
      if (linkError.reason) {
        if (linkError.reason !== 'Recipe found') {
          focusInput(linkField);
        }
        setSaveButtonClicked(false);
        return;
      }
    }

    /*
      custom-recipe serivce expects data from nlp service. Just gonna get everything in one shot.
    */
    if (recipeIngredients.length > 0) {
      props.nlpProcessorForMultipleLines(recipeIngredients.map((el) => {
        return el.value;
      })).then((data) => {

        if (data) {
          setFinalIngredientsPayload(data);
          setSaveButtonClicked(true);
        }
      });
    } else {
      setSaveButtonClicked(true);
    }
  };

  const handleCancelButton = () => {
    const config = {
      modalType: 'custom-recipe-cancel',
      title: 'Changes unsaved',
      subheading: 'Are you sure you want to leave this recipe?',
      includeCloseButton: false,
      buttons: [
        {
          text: 'Continue',
          clickHandler: () => {
            if (isAddMode) {
              props.history.push(`/profile/${props.username}/collections/${ALL_PERSONAL_RECIPES}`);
            } else {
              props.history.push(`/custom-recipe/${id}`);
            }
          },
        },
        {
          text: 'Cancel',
          type: 'secondary',
          clickHandler: () => {
            props.closeModal();
          },
        },
      ],
    };

    props.openModal(config);
  };

  const saveCancelButton = () => {

    const isSaveButtonDisabled = saveButtonClicked || recipeTitle.value.length === 0;
    return (
      <>
        <ActionBar className="composer-floating-bar flex-row justify-content-center" showBorder={true} justifyContentSpaceBetween={false}>
          <Button className="composer-action-save" disabled={isSaveButtonDisabled} onClick={handleSaveRecipeButton} label="Save Recipe" contained />
          <Button className="composer-action-cancel" onClick={handleCancelButton} label="Cancel" contained outline />
        </ActionBar>
      </>
    );
  };

  // if logged out, prompt to register/login
  if (!isLoggedIn) {
    const destination = (isEditMode && id)
      ? CUSTOM_RECIPE_EDIT_ROUTE.replace(':id', id)
      : CUSTOM_RECIPE_ADD_ROUTE;
    props.history.push(`/login?entry=${encodeURIComponent(destination)}`);
    return null;
  }

  // if logged in but not subscribed, show modal upsell
  if (!props.isProUser) {
    props.showPaywallModal(true, CHECKOUT_SOURCES.customRecipe, null, null, {
      redirect: HOME_ROUTE,
    });
    // return null; // currently we should still show the page behind the modal for this case, but NOT for the logged out case
  }

  return (
    <React.Fragment>
      <div className="composer">
        <div className="composer-forms">
          <div className="composer-header pst-h3">{recipeHeader}</div>
          <div className="composer-section">
            <div className="composer-section-left">
              {formTitle()}
              {formLink()}
              <FormTotalTime/>
              <div className="composer-required-field micro-text font-bold"><span className="required-field"/>{"Required Field"}</div>
            </div>
            <div className="composer-section-right">
              {formImage()}
            </div>
          </div>
          <div className="composer-separator" />
          {formIngredients()}
          {formDirections()}
          {saveCancelButton()}
        </div>
      </div>
    </React.Fragment>
  );
}

CustomRecipeComposer.propTypes = {
  closeModal: YummlyPropTypes.action,
  createCustomRecipe: YummlyPropTypes.action,
  fetchCustomRecipe: YummlyPropTypes.action,
  history: YummlyPropTypes.history,
  image: YummlyPropTypes.string,
  isLoggedIn: YummlyPropTypes.bool,
  isProUser: YummlyPropTypes.bool,
  loadRecipe: YummlyPropTypes.action,
  location: YummlyPropTypes.location,
  match: YummlyPropTypes.match,
  nlpProcessorForMultipleLines: YummlyPropTypes.action,
  nlpProcessorForOneLine: YummlyPropTypes.action,
  openModal: YummlyPropTypes.action,
  saveImage: YummlyPropTypes.action,
  showNotification: YummlyPropTypes.action,
  showPaywallModal: YummlyPropTypes.action,
  showPopupMenu: YummlyPropTypes.action,
  updateCustomRecipe: YummlyPropTypes.action,
  urlTester: YummlyPropTypes.action,
  username: YummlyPropTypes.string,
  yumRecipe: YummlyPropTypes.action,
};

const mapStateToProps = state => {
  return {
    error: state.customRecipes.loadingError,
    isLoggedIn: isLoggedInSelector(state),
    isProUser: isProUser(state),
    image: state.customRecipes.recipeItem?.imageURL,
    username: authUsernameSelector(state),
  };
};

const mapDispatchToProps = {
  closeModal,
  createCustomRecipe,
  fetchCustomRecipe,
  loadRecipe,
  nlpProcessorForMultipleLines,
  nlpProcessorForOneLine,
  openModal,
  saveImage,
  showNotification,
  showPaywallModal,
  showPopupMenu,
  updateCustomRecipe,
  urlTester,
  yumRecipe,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomRecipeComposer));

// import pluralize from 'pluralize';
import { DEFAULT_CATEGORY, DEFAULT_CATEGORY_ID } from 'util/shoppingList';

// adapter to allow Custom Recipes to be used by methods expecting regular(?) recipe objects
// NOTE: not every recipe property is represented, new code that calls this function MAY need to modify it to suit.
export default function convertRecipe(customRecipe) {
  // default values
  const recipe = {
    displayServings: 1,
    id: customRecipe.id,
    image: customRecipe.imageUrl, // TODO imageHeight? imageWidth?
    ingredientLines: {
      imperial: [],
      metric: [],
    },
    name: customRecipe.title,
    prepSteps: customRecipe.preparation ? [{
      index: 0,
      step: customRecipe.preparation,
    }] : [],
  };

  (customRecipe.ingredientLines || []).forEach(line => {
    // TODO post-mvp: handle multiple ingredients on a single line (details tbd)
    const firstIngredient = line?.ingredients?.[0] || {};
    const newLine = {
      ingredient: line.alternativeLine || line.rawLine,
      ingredientId: firstIngredient.ingredientId,
      categoryId: firstIngredient.groceryCategoryId || DEFAULT_CATEGORY_ID,
      category: firstIngredient.groceryCategoryLabel || DEFAULT_CATEGORY,

      // NOTE: API currently only sends the String quantity.label which could be a fraction like "1/2", when it begins sending the Number quantity.num we can pass the following items:
      // amount: firstIngredient.quantity?.num,
      // abbreviation: firstIngredient.unit?.label,
      // unit: pluralize.singular(firstIngredient.unit?.label),
      // pluralUnit: pluralize.plural(firstIngredient.unit?.label),
    };

    // CRs use the units as entered by the user, it's "inaccurate but acceptable" to copy into imperial AND metric
    recipe.ingredientLines.imperial.push(newLine);
    recipe.ingredientLines.metric.push(newLine);
  });

  recipe.isCustomRecipe = true; // in case something downstream needs to be sure

  return recipe;
}

import React from 'react';
import Image from 'components/Image';

import cdn from 'util/cdn';

const CustomRecipeDirections = props => {
  const { directions } = props;

  if (!directions || typeof directions !== 'string') {
    return (
      <div className="custom-recipe-directions empty-recipe-section">
        <Image
          src={`${cdn.imagePrefix()}/custom-recipes/empty-directions.png`}
          width="64"
          height="64"
        />
        <h3>{`No directions entered`}</h3>
        <p>{`Edit your recipe to add cooking instructions (future you will appreciate it).`}</p>
      </div>
    );
  }

  return (
    <div className="custom-recipe-directions p2-text force-line-breaks">
      {directions.split('\n').map((step, i) => <p key={i}>{step}</p>)}
    </div>
  );

};

CustomRecipeDirections.propTypes = {
  directions: YummlyPropTypes.string,
};

export default CustomRecipeDirections;

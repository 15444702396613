import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { deleteCustomRecipe, fetchCustomRecipe } from 'actions/CustomRecipes';
import { closeModal, openModal } from 'actions/Modal';
import { showPaywallModal } from 'actions/Paywall';
import * as types from 'constants/ActionTypes';
import CustomRecipeDirections from 'bundles/customRecipes/components/CustomRecipeDirections';
import CustomRecipeIngredients from 'bundles/customRecipes/components/CustomRecipeIngredients';
import GenericNotFound from 'components/GenericNotFound';
import Image from 'components/Image';
import Link from 'components/Link';
import ActionBar from 'components/Pasta/ActionBar';
import Button from 'components/Pasta/Button';
import RecipeSummaryItem from 'components/RecipeSummaryItem';
// import YumButton from 'components/YumButton';
import { pstIcons } from 'constants/FontFaceCharacters';
import { ALL_PERSONAL_RECIPES } from 'constants/index';
import { CHECKOUT_SOURCES } from 'constants/Pro';

import { authUsernameSelector, currentUserSelector, isLoggedInSelector } from 'selectors/auth';
import { isProUser } from 'selectors/pro';
import cdn from 'util/cdn';
import { convertTotalTime } from 'util/number';
import pluralize from 'util/pluralize';


const INGREDIENT = 'Ingredient';

const CustomRecipe = props => {
  const dispatch = useDispatch();
  const {
    author,
    customRecipes,
    deleteCustomRecipe,
    fetchCustomRecipe,
    history,
    isLoggedIn,
    isProUser,
    match,
    error,
  } = props;
  const { id } = match?.params || {};
  const {
    imageURL = `${cdn.imagePrefix()}/custom-recipes/no-recipe-image.png`,
    ingredientLines,
    preparation,
    title,
    totalTimeSeconds,
    url,
  } = customRecipes.recipeItem || {};
  const hasIngredients = Boolean(ingredientLines?.length);
  const hasTotalTime = Boolean(totalTimeSeconds);

  useEffect(() => {
    if (isLoggedIn && !isProUser) {
      props.showPaywallModal(true, CHECKOUT_SOURCES.customRecipe, null, null, {
        redirect: `/profile/${props.username}/collections/${ALL_PERSONAL_RECIPES}`,
      });
    }

    // if logged out, prompt to register/login
    if (!isLoggedIn) {
      const path = `/custom-recipe/${id}`;
      history.push(`/login?entry=${encodeURIComponent(path)}`);
    }
  });

  useEffect(() => {
    // if CR already exists in customRecipes.recipes store, update recipeItem
    const data = customRecipes.recipes.filter(e => e.id === id);
    if (!isEmpty(data)) {
      // Call this dispatch so it can update recipeItem object
      dispatch({
        type: types.CUSTOM_RECIPES_GET_ITEM_SUCCESS,
        data: data[0],
      });
    } else {
      fetchCustomRecipe(id);
    }
  }, [id, fetchCustomRecipe]);

  const width = 480; // TODO post-MVP: use srcSet attribute

  const scrollToDirections = () => {
    try {
      document.querySelector('.cr-directions').scrollIntoView({
        behavior: 'smooth',
      });
    } catch(e) {
      console.log('--- could not scroll to directions', e); // eslint-disable-line
    }
  };

  const doEdit = useCallback(() => {
    history.push(`/custom-recipe/edit/${id}`);
  }, []);

  const deleteConfirmation = (id) => {
    return {
      modalType: 'custom-recipe-delete-confirmation',
      title: 'Delete Recipe',
      subheading: 'Are you sure you want to delete this recipe?',
      includeCloseButton: false,
      buttons: [
        {
          text: 'Continue',
          clickHandler: () => {
            deleteCustomRecipe(id).then(() => {
              history.push(`/profile/${props.username}/collections/${ALL_PERSONAL_RECIPES}`);
            });
          },
        },
        {
          text: 'Cancel',
          type: 'secondary',
          clickHandler: () => {
            props.closeModal();
          },
        },
      ],
    };
  };

  const doDelete = useCallback(() => {
    if (!id) {
      return;
    }

    props.openModal(deleteConfirmation(id));
  }, [id, deleteCustomRecipe]);

  if (error) {
    return <GenericNotFound />;
  }

  return (
    <div className="custom-recipe p3-text background-light primary-dark">
      <section className="cr-summary flex-row space-between">
        <div className="flex-column justify-content-center">
          <h1 className="font-bold h2-text force-line-breaks">{title}</h1>
          <Link className="cr-author font-bold micro-text greyscale-3" href="/profile">{author}</Link>
          {
            Boolean(url) && (
              <Link className="cr-url p2-text" href={url}>
                <Image
                  src={`${cdn.imagePrefix()}/pasta/link.png`}
                  aria-label="Visit external recipe page"
                />
                {url}
              </Link>
            )
          }
          <div className="cr-summary-items flex-row">
            {hasIngredients && (
              <RecipeSummaryItem
                value={ingredientLines.length}
                unit={ingredientLines.length === 1 ? pluralize.singular(INGREDIENT) : pluralize.plural(INGREDIENT)}
              />
            )}
            {hasTotalTime && (
              <RecipeSummaryItem {...convertTotalTime(totalTimeSeconds)} />
            )}
          </div>
          <div className="directions-and-collections flex-row">
            <Button
              label="Read Directions"
              contained
              onClick={scrollToDirections}
            />

            {/* TODO post-MVP: modify YumButton to work with custom recipes */}

          </div>
        </div>
        <div className="cr-image">
          <Image
            src={imageURL}
            size={width}
            width={width}
            height={width}
            mode="crop"
          />
        </div>
      </section>
      <div className="section-wrapper">
        {
          (hasIngredients || preparation) ? <>
            <section className="cr-ingredients">
              <h3 className="h4-text">{`Ingredients`}</h3>
              <CustomRecipeIngredients ingredients={ingredientLines} />
            </section>
            <section className="cr-directions">
              <h3 className="h4-text">{`Directions`}</h3>
              <CustomRecipeDirections directions={preparation} />
            </section>
          </> : <>
            <section className="custom-recipe-ingredients custom-recipe-directions empty-recipe-section">
              <Image
                src={`${cdn.imagePrefix()}/custom-recipes/empty-directions.png`}
                width="64"
                height="64"
              />
              <h3>{`No ingredients or directions entered`}</h3>
              <p>{`Edit your recipe to add ingredients and cooking instructions to create this recipe.`}</p>
            </section>
          </>
        }
        <section className="cr-actions">
          <ActionBar>
            <Button
              label="Edit Recipe"
              iconCode={pstIcons.F0003_00B__Icon_edit}
              onClick={doEdit}
            />
            <Button
              label="Delete Recipe"
              iconCode={pstIcons.F0003_025__Icon_bin}
              onClick={doDelete}
            />
          </ActionBar>
        </section>
      </div>
    </div>
  );
};

CustomRecipe.propTypes = {
  closeModal: YummlyPropTypes.action,
  customRecipes: YummlyPropTypes.customRecipes,
  deleteCustomRecipe: YummlyPropTypes.action,
  fetchCustomRecipe: YummlyPropTypes.action,
  isLoggedIn: YummlyPropTypes.bool,
  isProUser: YummlyPropTypes.bool,
  match: YummlyPropTypes.match,
  openModal: YummlyPropTypes.action,
  showPaywallModal: YummlyPropTypes.action,
  username: YummlyPropTypes.string,
};

const mapStateToProps = state => {
  const currentUser = currentUserSelector(state);
  const author = [currentUser?.firstName, currentUser?.lastName].join(' ');
  return {
    author,
    customRecipes: state.customRecipes,
    error: state.customRecipes.loadingError,
    isLoggedIn: isLoggedInSelector(state),
    isProUser: isProUser(state),
    username: authUsernameSelector(state),
  };
};

const mapDispatchToProps = {
  closeModal,
  deleteCustomRecipe,
  fetchCustomRecipe,
  openModal,
  showPaywallModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomRecipe);

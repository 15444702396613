import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  addAllIngredientsFromRecipes,
  removeIngredientsFromRecipes,
} from 'actions/ShoppingList';
import AddIngredient from 'components/AddIngredient';
import ActionBar from 'components/Pasta/ActionBar';
import Button from 'components/Pasta/Button';
import Image from 'components/Image';
import { pstIcons } from 'constants/FontFaceCharacters';

import cdn from 'util/cdn';

const CustomRecipeIngredients = props => {
  const {
    addAllIngredientsFromRecipes,
    convertedRecipeItem,
    ingredients = [],
    removeIngredientsFromRecipes,
    shoppingListItems = [],
  } = props;

  const numIngsInList = ingredients.filter(
    (ingredient, index) => shoppingListItems.filter(
      item => (
        item['status'] === 'open' &&
        item['recipe-url-name'] === convertedRecipeItem.id &&
        item['description']?.toLowerCase() === ingredient.rawLine?.toLowerCase() &&
        item['order-in-recipe'] === index
      )
    ).length
  ).length;
  const hasAllIngredients = numIngsInList === ingredients.length;
  const cbAdd = useCallback(() => {
    addAllIngredientsFromRecipes([convertedRecipeItem], {/* TODO: tracking props? */});
  }, [convertedRecipeItem]);
  const cbRemove = useCallback(() => {
    removeIngredientsFromRecipes([convertedRecipeItem], {/* TODO: tracking props? */});
  }, [convertedRecipeItem]);
  const btnConfig = {
    text: hasAllIngredients
      ? 'Remove All from Shopping List'
      : 'Add All to Shopping List',
    icon: hasAllIngredients
      ? 'shopRemove'
      : 'shopAdd',
    handler: hasAllIngredients
      ? cbRemove
      : cbAdd,
  };

  return Boolean(ingredients.length) ? <>
    <ul className="custom-recipe-ingredients">
      {
        ingredients.map((ingredient, i) => {
          const name = ingredient.rawLine;
          const slIndex = shoppingListItems.findIndex(item => {
            return (
              item['status'] === 'open' && // item has not been crossed off or removed
              item['recipe-url-name'] === convertedRecipeItem.id && // item was added from this recipe
              item['description']?.toLowerCase() === name?.toLowerCase() && // ingredient name matches
              Number(item['order-in-recipe']) === i // is in the same order (in case the same ingredient appears twice)
            );
          });
          const isInShoppingList = slIndex > -1;
          return Boolean(name) && (
            <li key={i} className="cr-ingredient-line">
              <Button
                className="toggle-shopping-list hidden"
                iconCode={isInShoppingList ? pstIcons.F0003_00A__Icon_remove : pstIcons.F0003_009__Icon_add}
              />
              <AddIngredient
                recipe={convertedRecipeItem}
                ingredientEntity={convertedRecipeItem.ingredientLines.imperial[i]}
                index={i}
                existsInShoppingList={isInShoppingList}
                slIndex={slIndex}
              />
            </li>
          );
        })
      }
    </ul>
    <ActionBar className="cr-ings-action-bar" showBorder={true}>
      <Button
        iconName={btnConfig.icon}
        label={btnConfig.text}
        onClick={btnConfig.handler}
      />
      {/* TODO post-MVP: Add to Meal Planner */}
    </ActionBar>
  </> : (
    <div className="custom-recipe-ingredients empty-recipe-section">
      <Image
        src={`${cdn.imagePrefix()}/custom-recipes/empty-ingredients.png`}
        width="64"
        height="64"
      />
      <h3>{`No ingredients entered`}</h3>
      <p>{`Edit your recipe to include a list of ingredients to add to your Shopping List.`}</p>
    </div>
  );

};

CustomRecipeIngredients.propTypes = {
  ingredients: YummlyPropTypes.arrayOfObjects, // TODO: define CR-related proptypes and make this more specific
};

const mapStateToProps = state => {
  return {
    convertedRecipeItem: state.customRecipes.convertedRecipeItem,
    shoppingListItems: state.shoppingList.data.items,
  };
};

const mapDispatchToProps = {
  addAllIngredientsFromRecipes,
  removeIngredientsFromRecipes,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomRecipeIngredients);

